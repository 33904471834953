import request from '@/utils/request'

/**
 * 获取任务列表
 */
export const getArticleList = params => {
  return request({
    url: '/api/taskLis',
    params
  })
}

/**
 * 获取详情
 */
export const getTaskShow = params => {
  return request({
    url: '/api/task/show',
    params
  })
}

// 获取任务详情
export const getTaskRegistration = params => {
  return request({
    url: '/api/task/registration',
    params
  })
}

// 获取任务详情 - 报名列表
export const getTaskRegistrationList = params => {
  return request({
    url: '/api/task/registrationList',
    params
  })
}

// 获取任务详情 - 报名列表 - 后补
export const getTaskRegistrationList2 = params => {
  return request({
    url: '/api/task/registrationList2',
    params
  })
}

// 获取任务详情 -  后补 - 任务内容
export const getRepairSubTasks = params => {
  return request({
    url: '/api/task/repairsubtasks',
    params
  })
}

// 任务 - 报名列表 - 雇主确认
export const postRegistrationQueren = params => {
  return request({
    url: '/api/task/registrationQueren',
    method: 'POST',
    params
  })
}

// 用户  申请撤销或验收
export const getToapplyfor = params => {
  return request({
    url: '/api/task/toapplyfor',
    params
  })
}

// 用户- 申请撤销或验收列表
export const getMyToapplyforList = params => {
  return request({
    url: '/api/task/mytoApplytorlist',
    params
  })
}

// 雇主 - 申请撤销或验收列表
export const getGzToapplyforList = params => {
  return request({
    url: '/api/task/gztoApplytorlist',
    params
  })
}

// 雇主 - 申请撤销或验收 - 回复
export const getGzToapplyforHuifu = params => {
  return request({
    url: '/api/task/gztoApplytorhuifu',
    params
  })
}

// ------------- 我的任务 -------------- 服务商--------------------------------服务商任务列表---------------------
export const getUsTaskServer = params => {
  return request({
    url: '/api/task/ustaskserver',
    params
  })
}

// 乙方 - 任务详情 - 已发送过X次未回复的申请 task/toapplyfortypenum
export const getUsTaskToapplyforTypeNum = params => {
  return request({
    url: '/api/task/toapplyfortypenum',
    params
  })
}

// 我的任务 - 商务商 - 任务基本信息
export const getMyTaskShow = params => {
  return request({
    url: '/api/task/mytaskshow',
    params
  })
}

// 任务 - 商务商 - 任务基本信息 - 添加绩效
export const postAddPerformanc = params => {
  return request({
    url: '/api/task/myaddperformanc',
    method: 'POST',
    params
  })
}

// 任务 - 商务商 - 任务基本信息 - 绩效列表
export const postPerformancList = params => {
  return request({
    url: '/api/task/myperformanclist',
    params
  })
}

// 任务 -  任务基本信息 - 添加阶段成果
export const postAddResults = params => {
  return request({
    url: '/api/task/myaddresults',
    method: 'POST',
    params
  })
}

// 任务 - 商务商 - 任务基本信息 - 阶段成果
export const postResultsList = params => {
  return request({
    url: '/api/task/myresultslist',
    params
  })
}

// ------------- 我的任务 -------------- 服务商--------------------------------服务商任务列表---------------------

export const getUsTaskEmployers = params => {
  return request({
    url: '/api/task/ustaskemployers',
    params
  })
}

// ------------- 我的任务 -------------- 雇主--------------------------------任务详情---------------------

// 我的任务 - 雇主 - 任务基本信息
export const getGzTaskShow = params => {
  return request({
    url: '/api/task/gztaskshow',
    params
  })
}

// 雇主 - 任务绩效列表
export const getGzPerformancList = params => {
  return request({
    url: '/api/task/gzperformanclist',
    params
  })
}

// 雇主 - 阶段成果列表
export const getGzResultsList = params => {
  return request({
    url: '/api/task/gzresultslist',
    params
  })
}

// 雇主 - 主任务ID查已完成的子任务
export const getTaskEvaluationList = params => {
  return request({
    url: '/api/task/taskevaluationlist',
    params
  })
}

// 雇主 - 发送评价
export const getAddTaskEvaluation = params => {
  return request({
    url: '/api/task/addtaskevaluation',
    params
  })
}

// 用户 - 我收到的评价
export const getUsEvaluationList = params => {
  return request({
    url: '/api/task/usevaluationlist',
    params
  })
}

// 修改后补任务
export const getSubTasksEdit = params => {
  return request({
    url: '/api/task/subtasksedit',
    params
  })
}

// 查询子任务 后补任务  是否有未确认的
export const getHbTaskWQR = params => {
  return request({
    url: '/api/task/gztaskdqr',
    params
  })
}

/**
 * 删除文章
 */
// export const deleteArticle = articleId => {
//   return request({
//     url: `/article/delete/${articleId}`
//   })
// }
