<template>
  <div class="">
    <div class="Ptit">
      <strong class="tit">任务评价</strong>
    </div>
    <div v-if="mList.length > 0">
      <div class="pjBox">
        <div class="pjLi" v-for="(itm, itx) in mList" :key="itx">
          <h4>
            {{ itm.taskName }}
            <span class="rtime">{{ itm.completeDatetime }}</span>
          </h4>
          <div class="pjBx">
            <div class="rate">
              <el-rate v-model="itm.pjFen" disabled text-color="#ff4400" />
            </div>
            <div class="pjnr">
              <div class="">{{ itm.pjTex }}</div>
              <div
                v-if="itm.pjFile !== '' && itm.pjFile != null"
                class="imgLis"
              >
                <img
                  v-for="(iam, iax) in itm.pjFile.split(',')"
                  :key="iax"
                  :src="wurl + iam"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="paginationPage"
        />
      </div>
    </div>
    <div v-else class="wpj42">
      <div class="P24">
        <el-result
          icon="warning"
          title="暂无评价"
          sub-title="快去任务大厅接任务吧"
          ><template #extra>
            <el-button @click="goPage('/')">去任务页</el-button>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { getUsEvaluationList } from '@/api/task'
import { sign } from '@/utils/storage'
// import tools from '@/utils/tools'
import { useRouter } from 'vue-router'
const router = useRouter()
const wurl = window.g.baseURL
const mList = ref([])
const total = ref(0)
const pages = ref({
  page: 1,
  size: 10
})

const getPage = async () => {
  var res = await getUsEvaluationList(sign(pages.value))
  mList.value = res.list
  total.value = res.total
  delete pages.value.sign
}
getPage()

const paginationPage = v => {
  pages.value.page = v
  getData()
}

const goPage = ss => {
  router.push(ss)
}
</script>
<style lang="scss" scoped>
.pjBox {
  margin-top: 0px;
}
.pjLi {
  border-bottom: dotted 1px #ddd;
  padding: 18px 6px;
  h4 {
    font-size: 16px;
    font-weight: normal;
    color: rgb(20, 93, 202);
    .rtime {
      font-size: 12px;
      font-weight: normal;
      color: #999;
      float: right;
    }
  }
}
.pjBx {
  display: flex;
  margin-top: 14px;
  .rate {
    flex: 1;
  }
  .pjnr {
    flex: 3;
  }
}
.imgLis {
  padding-top: 12px;
  img {
    max-height: 140px;
    max-width: 140px;
    margin-right: 12px;
  }
}
</style>
